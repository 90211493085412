import React, { useState, useEffect } from 'react';
import { Box, Image } from '@chakra-ui/react';


const CultureBox = ({ language }) => {
  const bgImages = [
    "https://back.iverielebi.gr/bg1.jpg",
    "https://back.iverielebi.gr/bg2.jpg",
    "https://back.iverielebi.gr/bg3.jpg",
    "https://back.iverielebi.gr/bg4.jpg"
  ];
  
  const [currentBgImage, setCurrentBgImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBgImage(current => (current + 1) % bgImages.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, [bgImages.length]);

  return (
    <Box
      position="relative"
      height="400px"
      bg="blue.500"
      overflow="hidden"
    >
      <Image
        src={bgImages[currentBgImage]}
        alt="Cultural Background"
        objectFit="cover"
        width="100%"
        height="100%"
        style={{ opacity: 0.5 }}
      />
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        p={8}
        bg="rgba(255, 255, 255, 0.5)"
        borderRadius="lg"
        maxWidth="80%"
      >
        <Image
          src="https://back.iverielebi.gr/txt.png" 
          alt="Cultural Association Text"
          objectFit="contain"
          width="100%"
          height="100%"
        />
      </Box>
    </Box>
  );
};

export default CultureBox;

import React from 'react';
import { Box, Container, Flex, Text, IconButton, useColorModeValue } from '@chakra-ui/react';
import { FaFacebook } from 'react-icons/fa';

const Footer = () => {
  const currentYear = new Date().getFullYear(); // Get current year

  return (
    <Box
      bg={useColorModeValue('blue.50', 'blue.900')}
      color={useColorModeValue('blue.700', 'blue.200')}>
      <Container
        as={Flex}
        maxW={'6xl'}
        py={4}
        justify={'space-between'}
        align={'center'}>
        <Text fontSize="sm">
          © {currentYear} ყველა უფლება დაცულია - ბერძნულ-ქართულ კულტურულ ასოციაცია საბერძნეთში "ივერიელები"
        </Text>
        <IconButton
          as="a"
          href="https://www.facebook.com/HellenicGeorgianCulturalAssociation/"
          aria-label="Facebook"
          icon={<FaFacebook />}
          size="lg"
          colorScheme="blue"
          isRound={true}
          bg={useColorModeValue('blue.500', 'blue.600')}
          color={useColorModeValue('white', 'gray.200')}
          _hover={{
            bg: useColorModeValue('blue.600', 'blue.700'),
          }}
        />
      </Container>
    </Box>
  );
};

export default Footer;

import React, { useEffect } from 'react';
import { Box, Flex, Text, IconButton, Stack, Collapse, Button, useDisclosure } from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';

  
const NAV_ITEMS = [
  {
    label: {
      ge: 'მთავარი',
      gr: 'Αρχική',
    },
    href: '/',
  },
  {
    label: {
      ge: 'ჩვენ შესახებ',
      gr: 'Σχετικά με εμάς',
    },
    href: '/about',
  },
  {
    label: {
      ge: 'სიახლეები',
      gr: 'Ιστολόγιο',
    },
    href: '/news',
  },
  // Add Events item
  {
    label: {
      ge: 'ღონისძიებები',
      gr: 'Εκδηλώσεις',
    },
    href: '/events', // Update this href with the actual link to your events page
  },
  // Add Return to Georgia item
  {
    label: {
      ge: 'საქართველოში დაბრუნება',
      gr: 'Επιστροφή στη Γεωργία',
    },
    href: '/events/291', // Update this href with the actual link to your page about returning to Georgia
  },
  {
    label: {
      ge: 'კონტაქტი',
      gr: 'Επικοινωνία',
    },
    href: '/contact',
  },
];


export default function HeaderMenu({ language, onLanguageChange }) {
  const { isOpen, onToggle } = useDisclosure();

  useEffect(() => {
    // Set a dynamic browser tab title based on the language
    document.title = language === 'ge' ? 'მთავარი ბერძნულ-ქართულ კულტურულ ასოციაცია საბერძნეთში - Iverielebi' : 'Ελληνο-Γεωργιανή Πολιτιστική Ένωση στην Ελλάδα "Ιβηρίδες"    ';
  }, [language]);

  const toggleLanguage = () => {
    onLanguageChange(language === 'ge' ? 'gr' : 'ge');
  };

  return (
    <Box>
      <Flex
        bg="blue.500"
        color="white"
        minH={'80px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        align={'center'}
        justify={'space-between'}
        wrap={'wrap'}
      >
        <Flex
          flex={{ base: 1, md: 'auto' }}
          justify={{ base: 'center', md: 'start' }}
        >
          <Link to="/">
            <img src="https://back.iverielebi.gr/ivlogo.png" alt="Logo" />
          </Link>
        </Flex>

        <Flex
          display={{ base: 'none', md: 'flex' }}
          align={'center'}
          justify={'center'}
          flexGrow={2}
        >
          <DesktopNav language={language} />
        </Flex>

        <Flex
          flex={{ base: 1, md: 'auto' }}
          justify={{ base: 'center', md: 'end' }}
          align={'center'}
        >
          <Button
            onClick={toggleLanguage}
            colorScheme="blue"
            variant="solid"
            size="md"
            mx={2}
          >
            {language === 'ge' ? 'Ελληνικά' : 'ქართული'}
          </Button>

          <IconButton
            onClick={onToggle}
            icon={isOpen ? <CloseIcon w={4} h={4} /> : <HamburgerIcon w={6} h={6} />}
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
            color="white"
            display={{ base: 'flex', md: 'none' }}
          />
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav isOpen={isOpen} language={language} />
      </Collapse>
    </Box>
  );
}

const DesktopNav = ({ language }) => {
  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label[language]}>
          <Link to={navItem.href} style={{ textDecoration: 'none' }}>
            <Text
              p={2}
              fontSize={'lg'}
              fontWeight={500}
              fontFamily="'BPG Mrgvlovani Caps', sans-serif"
              color="white"
              _hover={{
                color: 'gray.200',
              }}>
              {navItem.label[language]}
            </Text>
          </Link>
        </Box>
      ))}
    </Stack>
  );
};

const MobileNav = ({ isOpen, language }) => {
  return (
    <Stack
      bg="blue.500"
      fontFamily="'BPG Mrgvlovani Caps', sans-serif"
      p={4}
      display={{ md: 'none' }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label[language]} label={navItem.label[language]} href={navItem.href} isOpen={isOpen} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, href, isOpen }) => {
  return (
    <Stack spacing={4}>
      <Flex
        py={2}
        as={Link}
        to={href}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}>
        <Text
          fontWeight={600}
          color="white"
          fontSize={'lg'}>
          {label}
        </Text>
      </Flex>
    </Stack>
  );
};


import React, { useState } from 'react';
import { ChakraProvider, Flex, Box } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Banner from './Banner';
import Services from './Services';
import Blog from './Blog';
import NewsList from './NewsList';
import NewsDetail from './NewsDetail';
import AboutUs from './About';
import Calendar from 'react-calendar';
import TimeAndCalendar from './TimeAndCalendar'; 
import UsefulLinks from './UsefulLinks';
import Contact from './Contact';
import EventDetail from './EventDetail';
import EventsList from './EventsList ';
import Iframe from './Iframe';
import AdsComponent from './AdsComponent';
import './App.css';

function App() {
  const [language, setLanguage] = useState('ge'); // Default language Georgian

  return (
    <ChakraProvider>
      <Router>
        <Header language={language} onLanguageChange={setLanguage} />
        <AdsComponent language={language} onLanguageChange={setLanguage} />

        <Flex direction="column" minHeight="100vh">
          <Box flex="1">
            {/* Main content wrapped in Box to push Footer down */}
            <Routes>
              <Route path="/about" element={<AboutUs language={language} />} />
              <Route path="/News/:id" element={<NewsDetail language={language} />} />
              <Route path="/News" element={<NewsList language={language} />} />
              <Route path="/Contact" element={<Contact language={language} />} />
              <Route path="/iframe" element={<Iframe />} />
              <Route path="/events" element={<EventsList language={language} />} />
              <Route path="/events/:id" element={<EventDetail language={language} />} />


              <Route path="/" element={
                <>
                  <Banner language={language} />
                  <Services language={language} />
                  <Blog language={language} />

                  <TimeAndCalendar language={language} />
                  <UsefulLinks language={language} />

                </>
              } />
            </Routes>
          </Box>
          <Footer language={language} />
        </Flex>
      </Router>
    </ChakraProvider>
  );
}

export default App;

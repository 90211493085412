import React, { useState } from 'react';

const IframeLoader = () => {
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      {loading && (
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          {/* Here you can add a custom loader */}
          <h2>Loading...</h2>
        </div>
      )}
    <iframe
    title="Login Page"
    src="#"
    style={{ width: '100%', height: '100%', border: 'none' }}
    onLoad={handleLoad}
/>
    </div>
  );
};

export default IframeLoader;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Image,
  Text,
  Heading,
  Button,
  useMediaQuery,
  Flex,
  Spacer,
  Tag,
  useColorModeValue,
  VStack,
  chakra
} from '@chakra-ui/react';

function NewsList({ language }) {
  const [news, setNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [newsPerPage] = useState(10);
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  const navigate = useNavigate();

  useEffect(() => {
    fetch('https://back.iverielebi.gr/api.php?endpoint=posts')
      .then(response => response.json())
      .then(data => {
        const sortedNews = data.sort((a, b) => b.id - a.id);
        setNews(sortedNews);
      })
      .catch(error => console.error('Error fetching data:', error));
    document.title = language === 'ge' ? 'სიახლეები' : 'News';
  }, []);

  const indexOfLastNews = currentPage * newsPerPage;
  const indexOfFirstNews = indexOfLastNews - newsPerPage;
  const currentNews = news.slice(indexOfFirstNews, indexOfLastNews);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const getDescription = (item) => {
    const text = language === 'ge' ? item.text_geo : item.text_eng;
    const cleanText = text.replace(/<[^>]*>?/gm, '');
    return cleanText.length > 100 ? `${cleanText.substring(0, 97)}...` : cleanText;
  };

  const cardBgColor = useColorModeValue('white', 'gray.700');
  const hoverBgColor = useColorModeValue('gray.50', 'gray.600');

  return (
    <Box p={5} bg={useColorModeValue('gray.100', 'gray.900')}>
      <Grid templateColumns={isLargerThan768 ? 'repeat(3, 1fr)' : 'repeat(1, 1fr)'} gap={6}>
        {currentNews.map(item => (
          <Box
            key={item.id}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            cursor="pointer"
            bg={cardBgColor}
            boxShadow="lg"
            transition="all 0.3s"
            _hover={{ transform: 'translateY(-5px)', boxShadow: 'xl', bg: hoverBgColor }}
            onClick={() => navigate(`/News/${item.id}`)}
          >
            <Image src={`https://back.iverielebi.gr/img/blog/${item.img}`} alt={item.title_geo} width="100%" height="200px" objectFit="cover" />
            <VStack p={5} align="start" spacing={4}>
              <Heading fontSize="xl">{language === 'ge' ? item.title_geo : item.title_eng}</Heading>
              <Text fontSize="sm" color="gray.500">{item.seo_end}</Text>
              <Text noOfLines={3}>{getDescription(item)}</Text>
            </VStack>
          </Box>
        ))}
      </Grid>
      <Flex mt={8} justifyContent="center">
        {[...Array(Math.ceil(news.length / newsPerPage)).keys()].map(number => (
          <Button
            key={number}
            onClick={() => paginate(number + 1)}
            m={1}
            colorScheme="teal"
            variant="solid"
          >
            {number + 1}
          </Button>
        ))}
      </Flex>
    </Box>
  );
}

export default NewsList;

import React, { useState, useEffect } from 'react';
import { Box, Image, Link } from '@chakra-ui/react';

const AdsComponent = () => {
  const [ad, setAd] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const apiUrl = 'https://back.iverielebi.gr/adsapi.php?endpoint=posts'; // Consider renaming if ad blockers are an issue
  const imageBaseUrl = 'https://back.iverielebi.gr/img/Partners/';

  useEffect(() => {
    fetch(apiUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        if (data && data.length) {
          const highestIdAd = data.reduce((prev, current) => (prev.id > current.id) ? prev : current);
          setAd(highestIdAd);
        } else {
          throw new Error('No ads data found');
        }
      })
      .catch(error => {
        console.error('იმისთვის რომ ისარგებლოთ საიტით, გათიშეთ ნებისმიერი რეკლამის დაბლოკვის ექსთეშენი ბრაუზერიდან:', error);
        setError(error.message);
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <Box height="100vh" display="flex" justifyContent="center" alignItems="center">იტვირთება...</Box>;
  }

  if (error) {
    return <Box height="100vh" display="flex" justifyContent="center" alignItems="center">იმისთვის რომ ისარგებლოთ საიტით, გათიშეთ ნებისმიერი რეკლამის დაბლოკვის ექსთეშენი ბრაუზერიდან </Box>;
  }

  if (!ad) {
    return <Box height="100vh" display="flex" justifyContent="center" alignItems="center">No ad data available.</Box>;
  }

  const imageHeight = isMobile ? "125%" : "auto";

  return (
    <Box width="100%" overflow="hidden">
      <Link href={ad.link} isExternal>
        <Image 
          src={`${imageBaseUrl}${ad.img}`} 
          alt="Advertisement" 
          width="100%" 
          height={imageHeight}
          objectFit="cover" // or "contain", depending on your preference
        />
      </Link>
    </Box>
  );
};

export default AdsComponent;

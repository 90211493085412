import React from 'react';
import { Flex, VStack, Link, Box, Text, useColorModeValue, Icon } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const UsefulLinks = ({ language }) => {
  const bgColor = useColorModeValue('gray.100', 'gray.700');
  const textColor = useColorModeValue('blue.600', 'blue.200');
  const hoverBgColor = useColorModeValue('blue.50', 'blue.900');

  const links = [
    { ge: 'საქართველოს საგარეო საქმეთა სამინისტრო', el: 'Υπουργείο Εξωτερικών της Γεωργίας', url: 'http://mfa.gov.ge/' },
    { ge: 'საქართველოს საკონსულო მომსახურება', el: 'Προξενικές Υπηρεσίες', url: 'http://geoconsul.gov.ge/' },
    { ge: ' ელექტრონული ვიზის პორტალი', el: 'Ηλεκτρονικό Πύλη Βίζας της Γεωργίας', url: 'https://www.evisa.gov.ge/GeoVisa/' },
    { ge: 'ლეგალური მიგრაციის გზამკვლევი', el: 'Διαδικασία Νόμιμης Μετανάστευσης', url: 'http://migration.commission.ge/index.php?article_id=125&clang=0' },
    { ge: 'საქართველოს საკანონმდებლო მაცნე', el: 'Κρατικό Μητρώο της Γεωργίας', url: 'https://www.matsne.gov.ge/ka' },
    { ge: 'საქართველოს  საელჩო  საბერძნეთში', el: 'Πρεσβεία της Γεωργίας στην Ελλάδα', url: 'https://greece.mfa.gov.ge/' },

  ];

  return (
    <Box p={4} bg={bgColor} borderRadius="lg" boxShadow="xl" maxWidth="100%">
      <Flex wrap="wrap" justify="center" align="center">
        {links.map((link, index) => (
          <Box key={index} p={3} m={2} bg="white" borderRadius="md" boxShadow="md" _hover={{ bg: hoverBgColor }}
               flex="1" minW="240px" maxW="300px" textAlign="center">
            <Link href={link.url} isExternal>
              <VStack spacing={1}>
                <Icon as={ExternalLinkIcon} color={textColor} w={6} h={6} />
                <Text fontSize="md" fontWeight="semibold"               fontFamily="'BPG Mrgvlovani Caps', sans-serif"
 color={textColor}>
                  {language === 'ge' ? link.ge : link.el}
                </Text>
              </VStack>
            </Link>
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export default UsefulLinks;

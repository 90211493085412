import React, { useEffect, useState } from 'react';
import {
  Box, Container, Heading, Text, useColorModeValue, Divider, useBreakpointValue, Center,
} from '@chakra-ui/react';

const AboutPage = ({ language }) => {
  const [aboutText, setAboutText] = useState({ ge: "", gr: "" });

  useEffect(() => {
    // Fetch data from the API
    fetch('https://back.iverielebi.gr/apiabout.php?endpoint=posts')
      .then(response => response.json())
      .then(data => {
        const postData = data.find(post => post.id === 1);
        if (postData) {
          setAboutText({
            ge: postData.text_geo, // Georgian text
            gr: postData.text_rus // Assuming 'gr' is meant to be Russian text based on the API response
          });
        }
      });

    // Set a dynamic browser tab title based on the language
    document.title = language === 'ge' ? 'ჩვენს შესახებ - Iverielebi' : 'Σχετικά με εμάς - Iverilebi';
  }, [language]);

  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.600', 'gray.200');
  const dividerSize = useBreakpointValue({ base: '60%', md: '40%' });

  return (
    <Container maxW="container.lg" py={{ base: 10, md: 20 }} px={{ base: 4, md: 8 }} bg={bgColor}>
      <Box textAlign="center" mb={10}>
        <Heading
          fontSize={{ base: '4xl', md: '6xl' }}
          fontWeight="extrabold"
          fontFamily="'BPG Mrgvlovani Caps', sans-serif"
          bgGradient="linear(to-l, blue.400, blue.600)"
          bgClip="text"
        >
          {language === 'ge' ? 'ჩვენს შესახებ' : 'Σχετικά με εμάς'}
        </Heading>
        <Divider
          my={6}
          opacity="0.6"
          fontFamily="'BPG Mrgvlovani Caps', sans-serif"
          borderColor="blue.200"
          borderWidth={2}
          w={dividerSize}
          mx="auto"
        />
      </Box>

      <Center>
  <Text
    fontSize={{ base: 'lg', md: 'xl' }}
    color={textColor}
    lineHeight="tall"
    fontFamily="'BPG Mrgvlovani Caps', sans-serif"
    textAlign="justify"
    dangerouslySetInnerHTML={{ __html: aboutText[language] }}
  />
</Center>
    </Container>
  );
};

export default AboutPage;

import React, { useEffect, useState } from 'react';
import {
  Box, Container, Stack, Flex, Heading, Icon, Text, Link, VStack, HStack, useColorModeValue
} from '@chakra-ui/react';
import { FaFacebook, FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

const ContactPage = ({ language }) => {
  const [contactDetails, setContactDetails] = useState({ phone: '', email: '', address: '' });
  const textColor = useColorModeValue('gray.700', 'gray.50');
  const getInTouchText = language === 'ge' ? 'დაგვიკავშირდით' : 'Επικοινωνήστε μαζί μας';

  useEffect(() => {
    document.title = language === 'ge' ? 'კონტაქტი - ივერიელები' : 'Επικοινωνία - Η Εταιρεία Μας';

    // Fetch contact details from the API
    fetch('https://back.iverielebi.gr//apicontact.php?endpoint=posts')
      .then(response => response.json())
      .then(data => {
        const phone = data.find(item => item.id === 1).title_geo;
        const email = data.find(item => item.id === 2).title_geo;
        const address = data.find(item => item.id === 3).title_geo;
        setContactDetails({ phone, email, address });
      });

    const script = document.createElement('script');
    script.src = 'https://static.elfsight.com/platform/platform.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [language]);

  return (
    <Container maxW="container.xl" py={12}>
      <VStack spacing={10} alignItems="center">
        <Heading fontSize="4xl" textAlign="center" color={textColor}>{getInTouchText}</Heading>

        {/* Contact Information */}
        <HStack spacing={8} alignItems="center">
          <Box textAlign="center">
            <Icon as={FaPhone} w={8} h={8} color="blue.500" />
            <Text fontSize="lg" mt={2} color={textColor}>{contactDetails.phone}</Text>
          </Box>
          <Box textAlign="center">
            <Icon as={FaEnvelope} w={8} h={8} color="green.500" />
            <Text fontSize="lg" mt={2} color={textColor}>{contactDetails.email}</Text>
          </Box>
          <Box textAlign="center">
            <Icon as={FaMapMarkerAlt} w={8} h={8} color="red.500" />
            <Text fontSize="lg" mt={2} color={textColor}>{contactDetails.address}</Text>
          </Box>
        </HStack>

        {/* Google Map Embed */}
        <Box w="full" h="400px" boxShadow="lg" borderRadius="lg" overflow="hidden">
          <iframe
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ border: 0 }}
            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDpSs5y31v6ZvFNFAuiMRGaDStXP2pwnoc
            &q=${encodeURIComponent(contactDetails.address)}`}
            allowFullScreen
          />
        </Box>

        {/* Elfsight Contact Form */}
        <div className="elfsight-app-757f8148-9e51-4f9c-b108-6977ecd52522" data-elfsight-app-lazy></div>

        {/* Facebook Link */}
        <Link href="https://www.facebook.com/HellenicGeorgianCulturalAssociation/" isExternal color="blue.500" display="flex" alignItems="center">
          <Icon as={FaFacebook} w={6} h={6} mr={2} />
          <Text fontSize="lg" color={textColor}>
            {language === 'ge' ? 'გამოგვყევით Facebook-ზე' : 'Ακολουθήστε μας στο Facebook'}
          </Text>
        </Link>
      </VStack>
    </Container>
  );
};

export default ContactPage;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Image,
  Text,
  Heading,
  Flex,
  Container,
  Divider,
  VStack,
  useColorModeValue,
  Button,
  Badge,
  IconButton,
  Stack,
  LinkOverlay,
  LinkBox
} from '@chakra-ui/react';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';

function EventDetail({ language = 'ge' }) { // Default to Georgian if language prop is not specified
  const { id } = useParams();
  const [newsItem, setNewsItem] = useState(null);

  // Define default colors and styles
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.600', 'gray.200');
  const buttonColor = useColorModeValue('teal.500', 'teal.200');

  useEffect(() => {
    const fetchEventData = async () => {
      const fetchUrl = `https://back.iverielebi.gr/apievents.php?endpoint=posts&id=${id}`;
      try {
        const response = await fetch(fetchUrl);
        const data = await response.json();
        const item = data.find(item => item.id.toString() === id);
        if (item) {
          setNewsItem(item);
          // Dynamically update document title based on language
          document.title = language === 'ge' ? item.title_geo : item.title_rus;
          updateMetaTags(item);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchEventData();
  }, [id, language]);

  const updateMetaTags = (item) => {
    // Meta tag updates omitted for brevity
  };

  if (!newsItem) return <Box padding="20px" textAlign="center">Loading...</Box>;

  return (
    <Container maxW="container.xl" py={10} bgColor={bgColor} borderRadius="lg" boxShadow="sm" p={6}>
      <Flex direction={{ base: 'column', md: 'row' }} gap={10} alignItems="start">
        <Box width={{ base: '100%', md: '40%' }}>
          <Image 
            src={`https://back.iverielebi.gr/img/galery/${newsItem.img}`} 
            alt={language === 'ge' ? newsItem.title_geo : newsItem.title_rus} 
            borderRadius="lg"
            boxSize="100%"
            objectFit="cover"
          />
          <Stack direction="row" spacing={4} justifyContent="center" mt={4}>
          </Stack>
        </Box>
        <VStack width={{ base: '100%', md: '60%' }} align="start" spacing={5}>
          <Heading as="h2" size="xl" color={textColor}>{language === 'ge' ? newsItem.title_geo : newsItem.title_rus}</Heading>
          <Badge colorScheme="purple">{newsItem.category}</Badge> {/* Example category badge */}
          <Text color="gray.500" fontSize="sm">{newsItem.date}</Text> {/* Example date text */}
          <Divider />
          <Text color={textColor} dangerouslySetInnerHTML={{ __html: language === 'ge' ? newsItem.text_geo : newsItem.text_rus }} />
        </VStack>
      </Flex>
    </Container>
  );
}

export default EventDetail;

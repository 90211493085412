import React, { useState, useEffect } from 'react';
import { Box, SimpleGrid, Image, Text, Button, Heading, VStack, LinkBox, LinkOverlay, Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const truncateText = (text, maxLength) => {
  const plainText = text.replace(/<[^>]+>/g, ''); 
  return plainText.length > maxLength ? plainText.substring(0, maxLength) + '...' : plainText;
};

const BlogComponent = ({ language }) => {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    fetch('https://back.iverielebi.gr/api.php?endpoint=posts')
      .then(response => response.json())
      .then(data => {
        const sortedData = data.sort((a, b) => b.id - a.id).slice(0, 4);

        const transformedData = sortedData.map(post => ({
          id: post.id,
          title: truncateText(language === 'ge' ? post.title_geo : post.title_eng, 60), // Truncate title to 50 chars
          summary: truncateText(language === 'ge' ? post.text_geo : post.text_eng, 50), // Truncate description to 100 chars
          imageUrl: `https://back.iverielebi.gr/img/blog/${post.img}`,
          url: `/blog/${post.seo}`,
          date: post.seo_rus,
        }));

        setBlogPosts(transformedData);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [language]);


  return (
    <VStack spacing={8} my={10} mx={5} align="stretch">
      <Heading as="h2" size="xl" textAlign="center">
        <Text fontFamily="'BPG Mrgvlovani Caps', sans-serif" color="black">
          {language === 'ge' ? 'ბოლოს დამატებული სიახლეები' : 'Τελευταίες Ειδήσεις'}
        </Text>
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={5}>
        {blogPosts.map((post) => (
          <LinkBox as={Box} key={post.id} borderWidth="1px" borderRadius="lg" overflow="hidden" shadow="sm">
            {/* Adjust Image component */}
            <Image src={post.imageUrl} alt={`Image for ${post.title}`} width="100%" height="50%" objectFit="cover" />
            <Box p={5}>
              <Heading size="md" mb={2}>
                <LinkOverlay as={Link} to={`/News/${post.id}`}>
                  <Text fontFamily="'BPG Mrgvlovani Caps', sans-serif">{post.title}</Text>
                </LinkOverlay>
              </Heading>
              <Text fontFamily="'BPG Mrgvlovani Caps', sans-serif">{post.summary}</Text>
              <Text fontSize="sm" fontFamily="'BPG Mrgvlovani Caps', sans-serif" color="gray.500">
                {new Date(post.date).toLocaleDateString()}
              </Text>
            </Box>
          </LinkBox>
        ))}
      </SimpleGrid>
    <Flex justifyContent="center" mt={4}> {/* Added Flex container with center alignment */}
      <Link to="/news" style={{ textDecoration: 'none' }}>
        <Button
          colorScheme="gray"
          fontFamily="'BPG Mrgvlovani Caps', sans-serif"
        >
          {language === 'ge' ? 'ყველა დამატებული სიახლის ნახვა' : 'Δείτε όλες τις Ειδήσεις'}
        </Button>
      </Link>
    </Flex>

  </VStack>
);
};

export default BlogComponent;

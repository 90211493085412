import React, { useState, useEffect } from 'react';
import { Box, Grid, Text, Image, Button, VStack, Center } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const EventsList = ({ language }) => {
  const [events, setEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const eventsPerPage = 10;

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch('https://back.iverielebi.gr/apievents.php?endpoint=posts');
        const data = await response.json();
        if (data && Array.isArray(data)) {
          setEvents(data);
        } else {
          console.error('Data is not in expected format:', data);
        }
      } catch (error) {
        console.error('Failed to fetch events:', error);
      }
    };

    fetchEvents();
  }, []); // Removed language from dependencies to avoid refetching

  // Calculate the current events to display
  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = events.slice(indexOfFirstEvent, indexOfLastEvent);

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  // Dynamically set the button text based on the language
  const buttonText = language === 'gr' ? 'Μάθετε περισσότερα' : 'მეტის ნახვა';

  return (
    <Box>
      <Grid templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6} p={4}>
        {currentEvents.map((event) => (
          <VStack key={event.id} shadow="md" borderWidth="1px" borderRadius="md" overflow="hidden" align="stretch">
            <Image src={`https://back.iverielebi.gr/img/galery/${event.img}`} alt={language === 'gr' ? event.title_rus : event.title_geo} boxSize="200px" width="100%" objectFit="cover" />
            <VStack p={4} spacing={4} align="center" justify="center" flexGrow={1}>
              <Text fontWeight="bold" textAlign="center">{language === 'gr' ? event.title_rus : event.title_geo}</Text>
              <Button mt={2} colorScheme="blue" as={RouterLink} to={`/events/${event.id}`}>
                {buttonText}
              </Button>
            </VStack>
          </VStack>
        ))}
      </Grid>
      <Center mt={8}>
        {Array.from({ length: Math.ceil(events.length / eventsPerPage) }, (_, i) => (
          <Button key={i} onClick={() => paginate(i + 1)} m={1} colorScheme={currentPage === i + 1 ? "blue" : "gray"}>
            {i + 1}
          </Button>
        ))}
      </Center>
    </Box>
  );
};

export default EventsList;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Image, Text, Heading, Flex, Container, Divider, VStack, Button } from '@chakra-ui/react';
import { FaFacebook } from 'react-icons/fa';

function NewsDetail({ language }) {
  const { id } = useParams();
  const [newsItem, setNewsItem] = useState(null);

  const updateMetaTags = (item) => {
    const updateMetaTag = (name, content) => {
      let element = document.head.querySelector(`meta[property="${name}"]`);
      if (!element) {
        element = document.createElement('meta');
        element.setAttribute('property', name);
        document.head.appendChild(element);
      }
      element.setAttribute('content', content);
    };
  
    updateMetaTag('og:title', language === 'ge' ? item.title_geo : item.title_eng);
    updateMetaTag('og:description', language === 'ge' ? item.text_geo : item.text_eng);
    updateMetaTag('og:image', `https://back.iverielebi.gr//img/blog/${item.img}`);
    updateMetaTag('og:url', `https://iverielebi.gr/News/${item.id}`);
  };

  useEffect(() => {
    const fetchUrl = `https://back.iverielebi.gr/api.php?endpoint=posts&id=${id}`;

    fetch(fetchUrl)
      .then(response => response.json())
      .then(data => {
        const item = data.find(item => item.id.toString() === id);
        setNewsItem(item);
        if (item) {
          document.title = language === 'ge' ? item.title_geo : item.title_eng;
          updateMetaTags(item);
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [id, language]);

  if (!newsItem) return <Box>სიახლე იტვირთება...</Box>;

  const title = language === 'ge' ? newsItem.title_geo : newsItem.title_eng;
  const text = language === 'ge' ? newsItem.text_geo : newsItem.text_eng;

  // Stylish Facebook share button
  const FacebookShareButton = ({ url }) => (
    <Button
      as="a"
      href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&src=sdkpreparse`}
      target="_blank"
      rel="noopener noreferrer"
      leftIcon={<FaFacebook />}
      colorScheme="facebook"
      mt={5}
    >
      Facebook-ზე გაზიარება
    </Button>
  );

  return (
    <Container maxW="container.lg" py={10}>
      <Flex direction={{ base: 'column', md: 'row' }} gap={6} alignItems="start">
        <Box flexShrink={0}>
          <Image 
            src={`https://back.iverielebi.gr//img/blog/${newsItem.img}`} 
            alt={title} 
            borderRadius="md"
            boxSize="300px"
            objectFit="cover"
          />
          {/* Improved Facebook Share Button */}
          <FacebookShareButton url={`https://iverielebi.gr/News/${newsItem.id}`} />
        </Box>
        <VStack flex="1" align="start">
          <Heading as="h2" size="xl">{title}</Heading>
          <Text color="gray.500" fontSize="sm">{newsItem.seo_rus}</Text> {/* Display date */}
          <Divider my={4} />
          <Box dangerouslySetInnerHTML={{ __html: text }} /> {/* Render HTML content */}
        </VStack>
      </Flex>
    </Container>
  );
}

export default NewsDetail;

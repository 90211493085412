import React from 'react';
import { Flex, Text, Box } from '@chakra-ui/react';

const TimeAndCalendar = ({ language }) => {
  const getFormattedDate = () => {
    const now = new Date();
    if (language === 'ge') {
      const days = ['კვირა', 'ორშაბათი', 'სამშაბათი', 'ოთხშაბათი', 'ხუთშაბათი', 'პარასკევი', 'შაბათი'];
      const months = ['იანვარი', 'თებერვალი', 'მარტი', 'აპრილი', 'მაისი', 'ივნისი', 'ივლისი', 'აგვისტო', 'სექტემბერი', 'ოქტომბერი', 'ნოემბერი', 'დეკემბერი'];
      return `${days[now.getDay()]}, ${months[now.getMonth()]} ${now.getDate()}, ${now.getFullYear()}`;
    } else {
      return now.toLocaleDateString('el-GR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    }
  };

  const formattedDate = getFormattedDate();

  const getCurrentTime = (timezone) => {
    const now = new Date();
    return new Intl.DateTimeFormat('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: timezone,
      hour12: false
    }).format(now);
  };

  return (
    <Box p={4} bgGradient="linear(to-r, #edf2f7, white)">
      <Flex direction={['column', 'row']} align="center" justify="space-evenly" wrap="wrap">
        <Text fontSize="xl" fontWeight="semibold" fontFamily="'BPG Mrgvlovani Caps', sans-serif" mb={[2, 0]} mx={3}>
          {formattedDate}
        </Text>

        <Flex align="center" mx={3}>
          <Text fontSize="xl" fontWeight="" fontFamily="'BPG Mrgvlovani Caps', sans-serif">
            {language === 'ge' ? 'საქართველოს დრო: ' : 'Ελλάδα: '}
            {getCurrentTime('Asia/Tbilisi')} {/* Time zone for Georgia */}
          </Text>
        </Flex>

        <Flex align="center" mx={3}>
          <Text fontSize="xl" fontWeight="" fontFamily="'BPG Mrgvlovani Caps', sans-serif">
            {language === 'ge' ? 'საბერძნეთის დრო: ' : 'Γεωργία: '}
            {getCurrentTime('Europe/Athens')} {/* Time zone for Greece */}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default TimeAndCalendar;

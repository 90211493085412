import React from 'react';
import { Box, Text, SimpleGrid, Icon, Button, Container, Heading, Link as ChakraLink } from '@chakra-ui/react';
import { AtSignIcon, CalendarIcon, StarIcon, InfoIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';

const services = {
  ge: [
    // Georgian services
    { icon: AtSignIcon, text: 'დიასპორასთან ურთიერთობის და მომსახურების ცენტრი', href: '/contact' },
    { icon: InfoIcon, text: 'დავით გურამიშვილის სახელობის ქართული საკვირაო სკოლა', href: '/contact' },
    { icon: StarIcon, text: 'ხელოვნების სტუდია', href: '/contact' },
    { icon: StarIcon, text: 'ქართული ხალხური სიმღერებისა და ცეკვის სტუდია „ივერიელები“', href: '/contact' },
    { icon: CalendarIcon, text: 'ქართული ბიბლიოთეკა და ლიტერატურული სალონი', href: '/contact' },
    
  ],
  gr: [
    { icon: AtSignIcon, text: 'Κέντρο σχέσεων και υπηρεσιών διασποράς', href: '/contact' },
    { icon: InfoIcon, text: 'Γεωργιανό κατηχητικό σχολείο με το όνομα Davit Guramishvili', href: '/contact' },
    { icon: StarIcon, text: 'Στούντιο ΤΕΧΝΗΣ', href: '/contact' },
    { icon: StarIcon, text: 'Στούντιο γεωργιανών λαϊκών τραγουδιών και χορού "ΙΒΕΡΙΕΛΕΜΠΙ"', href: '/contact' },
    { icon: CalendarIcon, text: 'Γεωργιανή βιβλιοθήκη και λογοτεχνικό σαλόνι', href: '/contact' },
    ],
};


  const ServiceBox = ({ icon, text, href }) => {
    const gradient = 'linear(to-r, blue.50, white)';
    const IconComponent = icon;
  
    return (
      <Box
        p={6}
        shadow="md"
        borderWidth="1px"
        borderRadius="lg"
        align="center"
        bgGradient={gradient}
        color="gray.700"
        fontFamily="'BPG Mrgvlovani Caps', sans-serif"
        _hover={{ shadow: "lg", transform: "translateY(-2px)", transition: "all 0.3s ease-in-out" }}
        maxWidth="530px"  // Increased box size
        width="100%"      // Ensure full width is used
      >
        <Icon as={IconComponent} w={12} h={12} mb={4} color="blue.500" />
        <Text fontSize="lg" fontWeight="bold" mb={4}>{text}</Text>
        <ChakraLink as={Link} to={href}>
          <Button colorScheme="blue" variant="outline" size="md" _hover={{ textDecoration: 'none' }}>
            სერვისის მიღება
          </Button>
        </ChakraLink>
      </Box>
    );
  };
  
  const ServicesGrid = ({ language }) => {
    const title = language === 'ge' ? 'ჩვენი სერვისები' : 'Οι Υπηρεσίες μας';
  
    return (
      <Container maxW="container.xl" py={5} fontFamily="'BPG Mrgvlovani Caps', sans-serif">
        <Heading as="h2" size="xl" textAlign="center" mb={8} color="blue.600" fontFamily="'BPG Mrgvlovani Caps', sans-serif">
          {title}
        </Heading>

        
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} mt={5} >
          {services[language].map((service, index) => (
            <ServiceBox key={index} icon={service.icon} text={service.text} href={service.href} />
          ))}
        </SimpleGrid>
      </Container>
    );
  };
  
  export default ServicesGrid;